import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Tasker Android App - Schedule Alarm Task",
  "path": "/Advanced_User/Tasker/Schedule_Alarm_Task/",
  "dateChanged": "2017-12-05",
  "author": "Mike Polinowski",
  "excerpt": "Use a macro tool to automate functions of your camera",
  "image": "../AU_SearchThumb_Tasker.png",
  "social": "/images/Search/AU_SearchThumb_Tasker.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_Tasker_white.webp",
  "chapter": "Advanced User"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='Tasker Android App - Schedule Alarm Task' dateChanged='2017-12-05' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use a macro tool to automate functions of your camera' image='/images/Search/AU_SearchThumb_Tasker.png' twitter='/images/Search/AU_SearchThumb_Tasker.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/Tasker/' locationFR='/fr/Advanced_User/Tasker/' crumbLabel="Tasker" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "tasker-android-app---schedule-alarm",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#tasker-android-app---schedule-alarm",
        "aria-label": "tasker android app   schedule alarm permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Tasker Android App - Schedule Alarm`}</h1>
    <h2 {...{
      "id": "add-a-task-to-timed-adjust-motion-detection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#add-a-task-to-timed-adjust-motion-detection",
        "aria-label": "add a task to timed adjust motion detection permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a task to timed adjust motion detection`}</h2>
    <p>{`In the PREVIOUS STEP we have defined 2 tasks that will cause the camera to pan between a daytime and a nighttime position. Now we want to adjust the motion detection in the camera for these two positions, as at night often a much lower sensitivity is already sufficient. This is followed by the creation of a TIME-CONTROLLED PROFILE that allows us to perform these tasks automatically at sunrise or sunset. By the same principle, you can address almost all camera functions - both for our HD cameras, as well as the VGA models. The required CGI commands can be found in our CGI documentation:`}</p>
    <h3 {...{
      "id": "add-a-task-to-activate-the-motion-detection-area-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-a-task-to-activate-the-motion-detection-area-1",
        "aria-label": "add a task to activate the motion detection area 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a task to activate the motion detection area 1`}</h3>
    <p>{`Now we need a task that allows us to activate the motion detection area 1 with a sensitivity of 75% (for the day position) and at the same time deactivate all other areas.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "432px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a7edd631b0dd8218fced863621e5e1f1/0e0c3/Tasker_MD_Day_(1).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "177.82608695652172%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAkCAIAAAAGkY33AAAACXBIWXMAAAsTAAALEwEAmpwYAAAC30lEQVRIx+2VS3PTMBCA5fiVhyXbsZzIkpzEdlwlda2hnZIAk9KkgQFSHhdOZbhw5H9wZThx5LeCq7TTlk5xOXHgGx1Wjx2tdle7YDabnZysy1KORiPtgkajAQBIkuTw8LA8Z71eF0XBGBNCrFYrzjkhBNjNFuWx73umZWmNxuUAmtZqtx0ILdu2m03KGETIsuxms4XD0G42LdsGYdD98eXTgBMAgKZpoB64221oGvAIe3L0GBGuQ9/2sO1hy8Wm27W8YDv1AtMNLA/bPr5c6YSk6YcgSsb9JB9NitGkYGlOkzHPdtLpXjwWNBnTNI/zSVbIkdilac7OR7WbT6JkDDiNjo8WL54/n88echoxSnfyfLN5JcuSRlFE+nu709enm0fzGSV9TqMBZyfL5QMpSS8EfrcLIYII+b6Pe/3oaENW7/Bi0z9+Gx2/CVnc9X3kedXuBRAhB0KMMeh0Oq7rtlqtrSt0A+imZlrAMIFuAqD97kjHcTjn1eJwOCzLkjFWx9vqwGAwWK/Xpmlu5yoxKnT92rgNpVIlEkIoCILqATVQakEQSCkr5TiOhRBpmtZXZoydnp7atn3L9h9RNleC67q9Xs/zPNd1EUK6rt+hpj5MFEWz2aySOefL5XI6nRZFIaW0LOsOs5XydDo9OztT8t+YvX1/p9PBGLuuaxhGzTiHYSiEqOZ5nk8mE0ppFfR63k6SZLFY3Nvgm2YjhAghGGPDMGre7DjO/v5+Fecsy/I8Hw6Hd/v5qjKEcLlcttvta2GoyeV3uMdTb2RL/YL3n38AmyYNzCyamB1o6A2jHipbgHj5fvHxc/H2Q1bup6NRmmbpnWRZplqvlBKUk53v374+e7qIOdsRIq9BlmWr1Wo+n4N+FGXjnMVx2OuFt6FazI3FbbVWKer7/sHBgWo6uq4LIcIwVE5pt9tXa5uUEkJ4raY5jpOmqTqhaRohBCGkZNM0CSGXv51Sqir2r1t/AqOXX9J1iSN2AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a7edd631b0dd8218fced863621e5e1f1/e4706/Tasker_MD_Day_(1).avif 230w", "/en/static/a7edd631b0dd8218fced863621e5e1f1/01822/Tasker_MD_Day_(1).avif 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a7edd631b0dd8218fced863621e5e1f1/a0b58/Tasker_MD_Day_(1).webp 230w", "/en/static/a7edd631b0dd8218fced863621e5e1f1/0bafe/Tasker_MD_Day_(1).webp 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a7edd631b0dd8218fced863621e5e1f1/81c8e/Tasker_MD_Day_(1).png 230w", "/en/static/a7edd631b0dd8218fced863621e5e1f1/0e0c3/Tasker_MD_Day_(1).png 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a7edd631b0dd8218fced863621e5e1f1/0e0c3/Tasker_MD_Day_(1).png",
              "alt": "Tasker - Add a Task",
              "title": "Tasker - Add a Task",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol>
      <li parentName="ol">{`Make sure Tasker is enabled and click + to add a new task.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "432px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7e3e1c021ae8fcbc7811187b04e7c132/0e0c3/Tasker_MD_Day_(2).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "177.82608695652172%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAkCAIAAAAGkY33AAAACXBIWXMAAAsTAAALEwEAmpwYAAAFWUlEQVRIx41V6Y7bRhJujqxjDpHS6OIpieLZN0nxEHXOSJYcyxcSJ1hv/gyCJL+Spwiwu4+xr7pocWwg2OzCHwrFquoudn1FsgjKskyzzDCMLMtc172+vgYAXF1dAQBs207TlF5wOBwwxoZhBEHw+Pio67plWaB1fTMcadc317KitFqtq6sr6SJAkq5vbu7a7Uaz2Wy1dMNoy3Kj0Wy1rvuDQbPVarZaYNC7//c/f55YKgBAkiTwdejf319JEuioxnKzkEfGi3a32ek3O/2G0q8r941O79nt9OpKr9HpN7v9L5HbgdrqDoBmuyPbm4Z4GmJj5um2azr+DBLLDXTb1Wee5YUOZtMA6TPPuIhY9ULNdoGpa7vN+tXxWOSZqWuGrvuedz6/ZpTqmqapI4Lg2zfnRZHr6sjUtbFp7B8eIsbU4QB07+/bbbkty91utz8caZuz+vihvz6Pdu+13buBYd13u3KnI1Y/oy3Ld+12v98Ht7e3iqJUT0ig9gLU6lK9AV7UQa0OgPTfjby7uzNNUwQnkwml1DCMr+l2tWE8Hh8Oh3q9/uxLknRVoVb7k/wVqhTxIsmy3Ov1BIGvQJXW6/UYYyLZsqwgCGaz2dcn67p+Pp+bzeZfLFf4/3ep1WrioijKcDjsdDqKosiyXKvVKv53F7QvUGS58xmKovR6PU3Tbm9vgWEYu+02DEOMMWOsKkaSJIzQ6fhyt91sN5t0uYw5T+I4iSOhk2S/3wdB8D8LIzz68emnt99+9+tvv7/69AkxTuI5SeaI8SzLXr9+LdpknX4YPrw3X/3NCIk2HKqapqqqpqqQxyQraLZgecnyki9WxW6fbnbF9qEsy+VySQgRJ/zxj39tH/dT2/Z93/sMBCElhGBMMMIIMkLev3v7uNu9e/vmdDp9/Phxu92AMAh+enpKk8Q0TMdxZp8xnU4nljUZT6ZTuxLLGpuWZY3HxmWn4zjANMeqqgcBdF3fsia6bhqGZeimObEn0Xzsh6Y1Mc2xYVi+H0CIZzPX9wPfDx3HA5OJ7XnB8Xg6Hk+27QjWuqmONH08nb56Y7/5oJljTTWGQxVj+s035zxfrFfbly+P6TwDM8dzXN+2Hdf1Z47neoFz0cK2Hec54rteYM9cx/UdV+yfTh3H9QGLYh4nUZwkacaiOEkzysWF8ThJc8x4FeFxQhifZzm9RBiP51kOtg8Pi3K5XK3L1TpflEW5jOKER0kyz5I0S/MiX5RplperNaYsSubxPF2uN1mxeNgfAEQEEYoJg1gYwsUXjYQm9BLHtBKIiRBEMBEuCAJU1UAYj5I55VGaF5czUkQYoYxFcSxsSnlUMcJUcIKQAM+H6+0uKxZZLiRflKv1hkdxmuVZsXjcH9K8WK03lEdZvkjzYr3diQOKRQAxCEMMkajkuSRMBAVEQoQJ5ZRFIcQVNUK5sC9lh7YTzlwQIhoSHoYIU1YJjxJMGWEcEYqI8CmPRDLjhHIeJyHE+OMn/OF7EGIGeeL5sFyuRKl5UdWf5YXodpYvlqvNhVdRLsvl+nA8+T4k3/8df/sDCH0YemEYIIQpIfy5sYRW5X1xkeg5gZBARCEkwdSBouwkg6ttQKPQh2lahAg/MxcUKPzyeC7i+xAhihAJfMizEuBsQfYnFKWQxYiJ14hGMaJMtH2zLRZlmheYccIjwiKEOcIM0Zg9/cKefgGjoToaqOJLMKyRaqiarmq6dtGVMRKjQavc0UgfqULUia3q5vM07Xa7cRxXP51areb7/mAwqObRzc0NY6zRaFQuY6zdbv9pFN/e3s1ms2qHJEmqqsqyXNn1el1VVfFzuUDX9S9D8j9AXAoCp0cSkQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7e3e1c021ae8fcbc7811187b04e7c132/e4706/Tasker_MD_Day_(2).avif 230w", "/en/static/7e3e1c021ae8fcbc7811187b04e7c132/01822/Tasker_MD_Day_(2).avif 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7e3e1c021ae8fcbc7811187b04e7c132/a0b58/Tasker_MD_Day_(2).webp 230w", "/en/static/7e3e1c021ae8fcbc7811187b04e7c132/0bafe/Tasker_MD_Day_(2).webp 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7e3e1c021ae8fcbc7811187b04e7c132/81c8e/Tasker_MD_Day_(2).png 230w", "/en/static/7e3e1c021ae8fcbc7811187b04e7c132/0e0c3/Tasker_MD_Day_(2).png 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7e3e1c021ae8fcbc7811187b04e7c132/0e0c3/Tasker_MD_Day_(2).png",
              "alt": "Tasker - Add a Task",
              "title": "Tasker - Add a Task",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Name the task and confirm the entry.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "432px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/08d1342b18e679c117a8d029456594bd/0e0c3/Tasker_MD_Day_(3).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "177.82608695652172%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAkCAIAAAAGkY33AAAACXBIWXMAAAsTAAALEwEAmpwYAAAB/klEQVRIx+3Uz4vaQBQH8JlJzExMMvkdx8nGdcZqZHVz0GykqIjkIHjxIh6lZ/G/6J/Rf7aYdBdbhFKhPRQ/DOHl8R6BL0lAURRlWY7Hr3Ecg3cIIQCAECLP89dKWZaj0SiO4zRN1+s15zxJEoCJznjMeTtJEsdxkk7HDwIAIYBQbzZNy9IwxoTwOLYo1TRMiB4EISYEEwLkc+fb1y8vA/mW5y/DYTYePfE2uEXTGsPBAFZ16HsIQeC2n2af35jst0Sff0rNkANiNqinWq5K3cv1Uniq5WlO4PDk0qSu7rdU6gGom0AlkBhQN6FuKgZVTVsx6M0DiVEXqGkpBq3iAXersrnzPDzc9crd9pcfizHmnLuu6zhOq9VijHmeF4ZhFEWKovxmGSFEKTUqjuPYtm2apmVZtm3X/6b/MLCHf/Y9/PEOQghCiDGGECKEtCtK5ZfO9TBYrVZlWXY6ncViMZvNpJS9ihBiMplMp1MhxEenKIrNZiOE2G63y+USHI/H0+nEOT8ej7vdrtfr9StCiPl8vlwupZSDipSyLMvD4dDtds/n836/B77v27YdRZFbCa/474Ig+Ogwxuph3/d/5OS6bp7nuq4DABRFSdM0DMM6lGazmWWZpmn1bZZllmX9FLJhGFLKegJCyBijlNZ1o9FgjKmqWs/HcYwxrhe/A7M0PZ52i90hAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/08d1342b18e679c117a8d029456594bd/e4706/Tasker_MD_Day_(3).avif 230w", "/en/static/08d1342b18e679c117a8d029456594bd/01822/Tasker_MD_Day_(3).avif 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/08d1342b18e679c117a8d029456594bd/a0b58/Tasker_MD_Day_(3).webp 230w", "/en/static/08d1342b18e679c117a8d029456594bd/0bafe/Tasker_MD_Day_(3).webp 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/08d1342b18e679c117a8d029456594bd/81c8e/Tasker_MD_Day_(3).png 230w", "/en/static/08d1342b18e679c117a8d029456594bd/0e0c3/Tasker_MD_Day_(3).png 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/08d1342b18e679c117a8d029456594bd/0e0c3/Tasker_MD_Day_(3).png",
              "alt": "Tasker - Add a Task",
              "title": "Tasker - Add a Task",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Press the Plus button to open the Action Category dialogue.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "432px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3d3341938f419aeff41136f98ccc3813/0e0c3/Tasker_MD_Day_(4).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "177.82608695652172%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAkCAIAAAAGkY33AAAACXBIWXMAAAsTAAALEwEAmpwYAAAEq0lEQVRIx41W2VLbSBQVXgMCJl7AwkxmZKTe0dJaLNmOtXgRAolAyhU/JlV5z/+/TtlNFEzyMOdB1bfV597T57YWyfO85+fnsiw55wCAd+/eSZJUq9UkSVJV1fd9QoimaavVilKqqirGOEmS6+vrDx8+SK1Wu9e/eP/+L1mWm82G9Artdvvk5KTVasmyrCjK6elpq9Vqt9u9Xq/ZbLbbbens9HTKtX6v0+l0+/2LbrerKMrZ2Xm90ag3GkdHRyeyPBwOj2q1RqPZ6/Xq9XqtVjs+Pt6lbx6f3Iz+ZZRSBCiCGEKCINI1MFLBzQ3QNXgzQtoN1EbgZoQx1gFACClXQ6nRlKSjeq/XZ7OUZs80vSeTmMR3dPFAk5x+XNJoRRcFTe9pfEfna0wIxpgxdjW8lmr1XfF+t0OdMfm4ovMl8SfEn+5STJP9eEJmKV0UJFrtJjHGAFCMlMHlizEX1//chh+R7UHbxe6YeCFxx8QdQ+5D7u/Gzu6KnTFyQ+iGt5P4aqS9kPuXA2yYXjjNi0dnHCBmEMOC7DZaLKPFaheau9ANJ+v8fp4uiWEp13//JPd6BGPbssqiuGUMQUgw1jVtkabZeq1rGsEYQWjcsuenpzAICMaKovwk9/uEUsZYURQIIUwIoVTTddf1XM/TdJ1QiglBCCVpytgtYUxRrl6RCXEcZzabcc7RHrZtp2kaRZFt27uMeJdzOp0ahkEIOaxMiOu62+1WkEU/1ut1nueWZYl0jLHtdut5Hsb46uqwMmNss9lURQAApmlyznVdJ4RACCmlP378GI/HEMIDMqXUcRzP80QdjDGl1N2DMYYQEvsyDMP3fdM0B4PBQeXZbLbZbCaTCQCA7O3J87woCrwHIWQ+n3/69CnPc9u2D8iUUgjhfD6HEIqlqqrmeb7ZbFRVpZRijDVNC8NQ6DowjFLKOQ/D0DAMcRsh5DiO67pVyDn3fd+yLLZr1aHbSZI8PT2FYShkAwCSJFksFiKEEAZBUBTFfD5/2yqMMef827dvlT26rgdBEIahcBtjDCFM0/QPfaaUmqbpeR5jDP+EaZqWZVUhY8x1XcuyKKVvZadp+vnz59lsBvaPu2EY+R6maYptZ1n2+PhYlqXjOJeXlwdkwzC+f/8uFArZSZKsVqvXsrMs833/D26LYwAAECKF29VpFRlt2xbn54DMGMuyLM/zJEmq3sRxvF6vq7JBEJRluVwu/7Bnx3G2260wTMiOomi5XFayEUJlWQZB8Fa2uD2dTnVdFyIhhLZtc87FmRMz4/FYpP5F7na7rutmWRbH8f39vWg1YyzZwzAMCCFjbLFYxHGcZZnneQduM8aCIPjy5YvrukIFAKAoirIsKwtt2/769WsURW+PJ8bYsqyHhwdN06o9p2maZdnrVt3d3TmOgxB6+zxDCEejkTBGGA4AEBZUM5qmibfCQeXJZBJFkdBMX4ExRg8hjtOvyp1ORySGEOr/AxDCi4sL6Xcc7VENfg/F+C1NfNCrFNL/gVjX6XQ8zxNf3Xq9Tgip3lKyLHPOW62WCDnn5+fnL0RBlmV5NBo1m02xYjAYnJ2diXGj0RgMBo3Gy0/DcDjc/RPs8R+0/ig11RK2DAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3d3341938f419aeff41136f98ccc3813/e4706/Tasker_MD_Day_(4).avif 230w", "/en/static/3d3341938f419aeff41136f98ccc3813/01822/Tasker_MD_Day_(4).avif 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3d3341938f419aeff41136f98ccc3813/a0b58/Tasker_MD_Day_(4).webp 230w", "/en/static/3d3341938f419aeff41136f98ccc3813/0bafe/Tasker_MD_Day_(4).webp 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3d3341938f419aeff41136f98ccc3813/81c8e/Tasker_MD_Day_(4).png 230w", "/en/static/3d3341938f419aeff41136f98ccc3813/0e0c3/Tasker_MD_Day_(4).png 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3d3341938f419aeff41136f98ccc3813/0e0c3/Tasker_MD_Day_(4).png",
              "alt": "Tasker - Add a Task",
              "title": "Tasker - Add a Task",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Select Net.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "432px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cf27bcc96ceccce1a145802eb720f20b/0e0c3/Tasker_MD_Day_(5).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "177.82608695652172%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAkCAIAAAAGkY33AAAACXBIWXMAAAsTAAALEwEAmpwYAAAEm0lEQVRIx31Wa3OiSBTFaDSpjJkJRqNuMkTodwMizTMIERKcGDVOPmzt//8nu9ITH6mZPWVRza0+3nPuvTQoQRDMZrM0TV9fXwEArVZLUZRaraYoiqZpQghCiK7rRVFQSjVNQwhlWTYcDu/u7pRms3Wlqt1er9vrXX792my1mq2zZuvstNm8/PrtSlUvvnxpt9uapqmq2q4wGAzOzs6arZZy2f5iUWjoOri/h/oIQ4iAgQwDQQANHeojaBgQQl3XAQAQQnkdDoeKoiin5xfa91sze+bLf3j5k2Vz/vo3e16z9ImXGz7/ydKSck4/QCjlnA9vb5X6qaKc1DvX18z1aPzIopQ6ggbJ9uf61E/YdhEQSskHMCGMsf5gqJw0tsm7f33n4RS5PhIB8mMqAioC7EVIhMgLsRfJv8NejMQ2yKNsMAKKRKfbtSw7Q3iGcIIx5SYyTZeyvIp4hCLGmWmKIPDDyA8jezLZZpa4vu4wRvPR/dvt7dQwMMYIYxfC9d3t8vudgBBizBn7MZ8/PRWLxUsYBL1eb0e+ZpxzxiIh2M4bxq5t+45DMJYRwzCCIMAYU0r7/f6eTAkBhsEYAwBseRgbuk4IoZQCqQUhCOF4PEYIYYz3ZFVVXddN0zSO4yRJGGOmaWZZ9lBBRoQQ0+k0CAIZ3MtWVVUIUZblbDbL81ySy7LM83xWgXMeBEGe51mWzWazLMtubm72sgkhEMIwDEejkXQ4Go0mFfRKP0LIMIw4jitP+MizaZqcc9u2GWO0GomqxlguKKUYYwDAr6IQcuRZCPH29rbZbNbrtWVZEMKHh4eiKB4fH6fTKYTQdd3n5+c0TYuiSNP0SDalNE3TzWaTJImspxAiCIKwAkLItu0oDGXZkoeHI7JUO51OEUK/OgSAqGAAQClFGI8MI04SVI3QcZ8rV5+sSgnbCCEmxmOEbEotjB3G9uROpxNFUVmWRVHM5/PJZMIYWy6XLy8vT09Pr4sFsywBwOv9/Yum/dC0R4R6h+TxeOw4jhDC8zzTNCmlvu9HURRU4IxBQhyEMghjAAjnR7KlzyRJAABStq7rk8lECCH7TAlBGHthSLcPATsic87xB+SQUEoZY3I8yDHYJ8+2bb9UWK1WcsdqtVosFqsK22ojZFnWZrOJoohSOhgMjlpl27bv+5ZlSbJpmuPx2HVd27Z3OT3PYxWOZMs+x3G86/N2qoRwhZDzKPXHcfwbMv+A9Lk1RghDiB6UwLIsuYEfVrvT6TiOs1qtyrJcLpfbgSFkpuupYeSjUW4YCCHf99/W6yzL3t/f8zw/PoaqhJ7nkYNjyDRN27J2yeXAS4GfPWOMfd+HEO622rbtOA46IMtS/6ZgcshM08QHu3frXc0wxp8zU0qLonh/f0/TVD795A/4TO52u7x6G4VhKE/W/4Ecgf2QqKoKKozHY13XDcOQ1z8BQtjtdn+Ra7VavV5vNBq1Wu3k5ETenlQ4XEjsgsruI+Dq6srzvPPzc0VR6vU6IWR30FxcXDiO02w25a3jOO12e59W7tB1/fT0VAb7/f7l5aVcNxqNm5ubRqMhb4fDofz0+A//AiXmLtNKecGyAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cf27bcc96ceccce1a145802eb720f20b/e4706/Tasker_MD_Day_(5).avif 230w", "/en/static/cf27bcc96ceccce1a145802eb720f20b/01822/Tasker_MD_Day_(5).avif 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cf27bcc96ceccce1a145802eb720f20b/a0b58/Tasker_MD_Day_(5).webp 230w", "/en/static/cf27bcc96ceccce1a145802eb720f20b/0bafe/Tasker_MD_Day_(5).webp 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cf27bcc96ceccce1a145802eb720f20b/81c8e/Tasker_MD_Day_(5).png 230w", "/en/static/cf27bcc96ceccce1a145802eb720f20b/0e0c3/Tasker_MD_Day_(5).png 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cf27bcc96ceccce1a145802eb720f20b/0e0c3/Tasker_MD_Day_(5).png",
              "alt": "Tasker - Add a Task",
              "title": "Tasker - Add a Task",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`And then the HTTP GET action.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "432px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8506147c2e4c5cb82c5e8ad8d74bbf4d/0e0c3/Tasker_MD_Day_(6).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "177.82608695652172%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAkCAIAAAAGkY33AAAACXBIWXMAAAsTAAALEwEAmpwYAAAEA0lEQVRIx42W23KjOBCGBUggDhJnULANNj4EHIxN7DhxMmNPJrnIC+QiSVX2Jk+Qi6na232MfdpdoxkfkownX6kKAd1I+rvVApyfz1erb1++fL27u0vTVFEUAIAoigCAdrt9dnaW53mapre3t8PhMI7jLMtWq1Wj0eh0OkDBahg1Go2o2+tFUeQ4TqsVE0oBAJqmGYahappt24wx07Rsx8Gq6nqegjFWVZAm8b///FUVWVkUZ9PpMMtO8tx1nM34vu99/7aCEAIARsWJJEnrh64rigKwj5oXV+d+krrNthd3WDoQdSpoFBIHUUfQTeuo1R1NZNOF1NE9BomNqINtH1IbQMMUMEHEwpbLG/FCwwt1N+BNtVyZ2qrl8o5iOtAwdTdAxAL9bvd69XU+m16cz79cLqbVxCSGrmJDU3UV6yrW8LphRVYQVBCCoqAg+PePH4N+DxBKGWMhOwqC0PcD3w8MQnTd0HR93TRN1TSsqgrGSo0kSYSQ2ewMyTL4EC7Vp+h0OlVVZVl2cXHRarX6/R5j7LCLKAj1FSIaxWE6cOMO6x5bjcRptlUvBBoBOv1tMywgYyBBKIqiIIoQIVGSEJIRkmVFwVjFuF4qxrKiIEVG8voVN1MwFkVpnYM3Nzez2UzTNEopIYRSahiGXqPVqDVcMAihLMuvr6/9fh8QQprNZhAE+i+MGt7h/htnWZZFUSSELBYL+UO1hZ9ifC4KjLE8zweDQbPZTJJkMBgkSZKmabfbzWuyLCvL0jTNzdd5hq85Pj5eLpdVVeV5vlwuy7K8vLycTqfj8Xg6nQ4Gg+vr68Vi0Wq1iqKwLGtvdkEQxHHMGHMcJ47jKIqSJGk2m5ZlRVFEKfU8L45j13XTNHUcRxAEhNAHSxAEYR25GkmS+C2346PxJz+tj46O5vN5WZajmiAIDMMgNRvZecBwjSzLEMKXl5d1JfF9fzweF0UxHo9PT0+DIOAF5E2c8c7GME3z/v6eL/7ttKUdxH2EX2wdLMsKw9CyLNd1tzE4vDHqD22dbdt2XRfWqf5HuNlePgmCIO+A9oE1fC1btYfD4Wg0KoqCMbar1ocbAyEkSdLT01OSJMDzvJOTk/F4XFVVo9FQVZWsy5B+WO2Hhwfbtt8qIX3Ee8G3DpRS3/dN07Qs65PVa2tm23YYho7jcLU3KXmAPdl+J/iu5hu1t9NmjE0mk16vl+d5WZZZllFK9R3eqy2K4uPjY7vdXu/H09PTfr8/mUyurq663e5mS2xi9l7t5+dnpz4M/yz4IbX5/TZdD5axPQN+sW17NpupqspHzvM8DENup+t6VVWbWllVFa2P/rUjd9Z1vdPpIIS4RRiGhBDehxCGYcgPdwBAFEX81+N//gOVynSrcQQ5qgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8506147c2e4c5cb82c5e8ad8d74bbf4d/e4706/Tasker_MD_Day_(6).avif 230w", "/en/static/8506147c2e4c5cb82c5e8ad8d74bbf4d/01822/Tasker_MD_Day_(6).avif 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8506147c2e4c5cb82c5e8ad8d74bbf4d/a0b58/Tasker_MD_Day_(6).webp 230w", "/en/static/8506147c2e4c5cb82c5e8ad8d74bbf4d/0bafe/Tasker_MD_Day_(6).webp 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8506147c2e4c5cb82c5e8ad8d74bbf4d/81c8e/Tasker_MD_Day_(6).png 230w", "/en/static/8506147c2e4c5cb82c5e8ad8d74bbf4d/0e0c3/Tasker_MD_Day_(6).png 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8506147c2e4c5cb82c5e8ad8d74bbf4d/0e0c3/Tasker_MD_Day_(6).png",
              "alt": "Tasker - Add a Task",
              "title": "Tasker - Add a Task",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Now configure the HTTP GET command to send the CGI command to the IP address of your camera (see below).`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "432px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f56e535cd29cc0f0c1cf007877b45086/0e0c3/Tasker_MD_Day_(7).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "177.82608695652172%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAkCAIAAAAGkY33AAAACXBIWXMAAAsTAAALEwEAmpwYAAACGklEQVRIx+2Vz27iMBDGx7GJm38mTgI4CTWxCQ0tkJVoBAdAOXBA4tJL1SPaM+pj7GPsw+426aJuVWm1d36yrJlPMxrpO8zAfD6fzWb9/gAAEELQgBAyDAMApJRVVZVlWde11jrLsqIo6rqeTCZxHIPrsUGc+H5XiNj3+a2UYRS13YCQZdm241BKkyTxGHMc16Q0iiLHcTvUBCVvf/74Pp/m69Vq9nD/bbEYJvH7/Ob3XDfXGgBuqDm9mxCMASAKA8NAwOO0Wi2DYRYMR72Rtv0IblzCAuJx4nHs+lbQZ2LYYQFhQSS1FQ6w51vhgLAATJezXpLmRTadDfNCTqbE7SLLxbZnWC52mGF7qAmww4Dahu214lua5TwVg0xKOUx1Npo93BOCO9gwECIGulj4Ndn4NknTgRAijn3O8zxXSs0XCx4EcjQyKW3d//p9AqH3af+YeeXKlStXLnvzv3uMt/WOKKXtZTQ/gBs+KR+Loa7r/X4vpdxut+v1Wms9blBKLZfLx8dHpdRFWa1Wh8NBKXU8Hne7HZxOp/P5nCTJ6XR6enoaj8eTBqXUZrPZ7XZa67sGrfV+v395ecmy7PX19fn5GcIw7Ha7/X6fN/Q+EP4hiqKLIoRoi8MwfPeJc15VlWVZAIAxLoqi1+u1pti2XZalaZptWpal53l/mew4jta6rUAICSEYY23c6XSEEISQtj5NU9pcv9+NvwCO80Admt9GzQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f56e535cd29cc0f0c1cf007877b45086/e4706/Tasker_MD_Day_(7).avif 230w", "/en/static/f56e535cd29cc0f0c1cf007877b45086/01822/Tasker_MD_Day_(7).avif 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f56e535cd29cc0f0c1cf007877b45086/a0b58/Tasker_MD_Day_(7).webp 230w", "/en/static/f56e535cd29cc0f0c1cf007877b45086/0bafe/Tasker_MD_Day_(7).webp 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f56e535cd29cc0f0c1cf007877b45086/81c8e/Tasker_MD_Day_(7).png 230w", "/en/static/f56e535cd29cc0f0c1cf007877b45086/0e0c3/Tasker_MD_Day_(7).png 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f56e535cd29cc0f0c1cf007877b45086/0e0c3/Tasker_MD_Day_(7).png",
              "alt": "Tasker - Add a Task",
              "title": "Tasker - Add a Task",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`Then press the play button to test the action.`}</li>
    </ol>
    <p>{`Now (see 6 above) we need to create the HTTP command, which will be sent to the camera when the task is activated. Enter the IP address of your camera as Server Address - do not forget to attach the camera to Webport if it is not 80. If your camera has e.g. 192.168.178.200 and the camera is locally accessible via the port 8081 (the HTTP port can be viewed in the web interface under Network / IP configuration), you must enter 192.168.178.200:8081 as the server address. This is followed by the corresponding CGI command in the Path input field.`}</p>
    <p>{`The CGI command that activates Area 1 of your camera with 75% sensitivity is:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`/param.cgi?cmd=setmdattr&-name=1&-enable=1&-s=75&-x=0&-y=0&-w=1280&-h=720&cmd=setmdattr&-name=2&-enable=0&-s=30&-x=0&-y=0&-w=1280&-h=720&cmd=setmdattr&-name=3&-enable=0&-s=0&-x=0&-y=0&-w=1280&-h=720&cmd=setmdattr&-name=4&-enable=0&-s=0&-x=0&-y=0&-w=1280&-h=720`}</code></pre></div>
    <p>{`Please change in the command the username (usr) admin and password (pwd) instar according to your camera login. Please note that the command above will span area 1 over the entire image. But you can simply edit the size of the area directly in the command. The variables x and y correspond to the start point of the range (in the example above, both variables are equal to 0 - that means that the range starts in the lower right corner). The size of the area is determined by its width and height. In the example above, the width w = 1280 pixels and the height h = 720 pixels. This means that the area is covered over the entire image.`}</p>
    <h3 {...{
      "id": "add-a-task-to-activate-the-motion-detection-area-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-a-task-to-activate-the-motion-detection-area-2",
        "aria-label": "add a task to activate the motion detection area 2 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a task to activate the motion detection area 2`}</h3>
    <p>{`Now we need a task that allows us to activate the motion detection area 2 with a sensitivity of 30% (for the night position) and at the same time deactivate all other areas.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "432px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fb63b4cd3f7bbdadb5a88e2f200fc028/0e0c3/Tasker_MD_Night_(1).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "177.82608695652172%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAkCAIAAAAGkY33AAAACXBIWXMAAAsTAAALEwEAmpwYAAADDUlEQVRIx81Vy3LbNhQF3xQfICmCIgiQ4puUQpETexSp7ci1VDXTVm7TjVfOZOEs8x/ZZrrqst/a0pBdx/HYTFc9g+EAuDiDi3MfBIvFomka3/cBADzPc7fgeR4AMJ1Ol8tl27b7/b5pmiRJ6rrebrdFUcRxDAwTYhoqigxuGPcH4LiRpmm6rigqpaEJoaqOVHWEENINQ1VVgMbjvz6+j0MMQE8Hw+COHZ7jgB3Q786+hX4omI5iI8VGsoUkayzb7nFpI8lyZRspDrrdcXUPq44HgrTw0zKZNcmsoVlJ0iLMq2y+iIqapAXJyqic5U2X1C9IVtKb0VvLWZAWICTB+dnmp9ev16tXIQkoIVVZHg6/dG1LgiDA/uLF/NeLwzfrFcF+SIJpSH/Ybl92HZ54wBmPTROaEDqOgyZ+cHbAu9/R5uCfvwnOf/NoNHYcaNu99RYmhIZpIoSAruuWZY1Go6MUgggEiZNkIEpAkADgvhTSMIwwDPvNOI7btqWUDlGbHZhOp/v9XpKk45plRQ9B+Gw8BkbpswhC6Lpu/4ABYDTXdbuu68lRFNV1nWXZcDKl9OLiQlGUR8zP4q4KgGVZk8nEtm3LsiCEgiA8QWPVEgTBarXq52EYbrfb+XzeNE3XdbIsP+E2I8/n86urKzb/L24f36/rOkLIsixRFAfG2fO8uq77dVmWs9mMENIHfZjaaZpuNpuvdvih2xBCjDFCSBTFgTcbhnFyctLHOc/zqqriOH5a5/tkCOFut9M07TMDPwx35QB838/zHGNsmuazL2expZReXl4ek6SqqvQGz1YlsyZJcn19/fDkVwnef7+MwRDZHsnN4Rh+0/8RCkl5RGWSSropCrw4DCxbQP3z5ebdh+bN27w9yZIky/LsSeR5nqbpcrnsug60s+rPPz79+P0mCmlV1+UA5Hm+2+3W6zXwgyAvShpF3mTiPQb2i3mweezWLGKO45yenrKfjiAIdV17nsdE0TTtfm/ruo5Vwb89zTCMLMvYCY7jMMYQQjaXJAljfFfthBDWsf+59W95lmQOGJj9OAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fb63b4cd3f7bbdadb5a88e2f200fc028/e4706/Tasker_MD_Night_(1).avif 230w", "/en/static/fb63b4cd3f7bbdadb5a88e2f200fc028/01822/Tasker_MD_Night_(1).avif 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fb63b4cd3f7bbdadb5a88e2f200fc028/a0b58/Tasker_MD_Night_(1).webp 230w", "/en/static/fb63b4cd3f7bbdadb5a88e2f200fc028/0bafe/Tasker_MD_Night_(1).webp 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fb63b4cd3f7bbdadb5a88e2f200fc028/81c8e/Tasker_MD_Night_(1).png 230w", "/en/static/fb63b4cd3f7bbdadb5a88e2f200fc028/0e0c3/Tasker_MD_Night_(1).png 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fb63b4cd3f7bbdadb5a88e2f200fc028/0e0c3/Tasker_MD_Night_(1).png",
              "alt": "Tasker - Add a Task",
              "title": "Tasker - Add a Task",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`Press + to add a new task.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "432px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bdc24d10e0c62bf3a5f9e8e0b9fea8be/0e0c3/Tasker_MD_Night_(2).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "177.82608695652172%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAkCAIAAAAGkY33AAAACXBIWXMAAAsTAAALEwEAmpwYAAAFVUlEQVRIx41V6W7jRhJuWSPLtixKskTxlkhKPPomKZ66LWUcjDPj2cAz2E1+ZLBBEGCxCfIOATaPsc8atGgPEuTAfCg062B1f1VsFAElJAxDWZZd1238BmdnZwCAyWSSpiml9Hg8Yoxt2/Z9f7/f27ataRrodnuKql9ctM/b7Trpo4BG4/Lq6qrTabcvDMPsStLFxeXFxeVoJNdOMLq5+f8v304tFQDQaDTAp2F4Mzg7a4C+Ziw3paQYL7qDdn/U7o/Oe6NW7+a8P3wy+6NWb3jeH7UHo2fPsCOrFwMZaM5ccTw7xHaIDdfTnbk5811IrHmgO3Pd9SwvnGFmB0h3PeMkIuqFmjMHpq7tNuvPX74s8szUNUPXfc+7v3/FKNU1TVMVguDrL+7LItdVxdS1iWkc9vuIMXUsg8HNTbcrdSVpMBiMxoq2uVdv347W98ruQdu9kQ3rZjCQ+n0RfUZXkq673dFoBDqdTq/Xu7y8fGpF8wVothqtc/CiBZotABp/bOT19bVpmsI5nU4ppYZhfEq36xcmk8nxeGy1Wk92fSsEms3fyZ+hThG3SJKk4XAoCvgE1GnD4ZAxJpItywqCwHXdT0/Wdf3+/r7dbv9JuMbf79JsNsWj1+uNx+N+v9/r9SRJajabdf3XJ3Sf0X9Gr9cbDoeapnU6HWAYxm67DcMQY8wYq8k0Gg2M0N3Lz/bb7X673W03iySJoyiJoySOkyQ5HA5BEPwlMcKjrz/8++Hx3ff//eE/P/5UrDeQRTRJEeNZlr169Uq0ybp7P94/mJ//0wiJNh6rmqaqqqaqkMckK0lasLxiecWLVbE7pJtdsd1XVbVcLgkh4oSf//fL9vbgOK7v+94zEISUEEowxZggxAl9ePP6drd78/r13d3d4+PjZrMBYRB8+OabRZIYhuH+BvZ0OjWtqWVNJ1PbdmzbMS3LNC3LsgzDmJ0ATHNi224QQAix68513TTMiaEZVgDtOJ3y2PIC07B03QpDRAiFEEOIfT+YzTxgWdOqWr59++Xt/pAkC1lWdN1UZcXiifvuq9n7r00Wa4omy2ocLx4e/nG4/Wyz3h0Ox7KsgDvzHGfuzrzZzHfc+dwTW869wHXc+cxz3fn8FJp7ge3MhH/mOe5cBGceYFEcJQseJUmasShO0ozyKE4WtY4ZTzLh4XFCWbTIcrGmGePxIsvBdr8vq+Vyta5W67ysimoZxYnYa5ElaZbmRV5WaZZXqzWmLEoW8SJdrjdZUe4PRwARQYRiwiAWijDxaUViJfTkx7QWiIkQRDARJggCVHMgjEfJgvIozYvTGSkijFDGojgWOqU8OlWUYsp4nEBIgOfD9XaXFWWWC8nLarXe8ChOszwrytvDMc2L1XpDeZTlZZoX6+1OHFCUAcQgDDFEgskTJUxECYiECBPKKYtCiOvSCOVCP9EOnVnozkGIaEh4GCJMWS08SjBlhHFEKCLCpjwSyYwTynmchBDjx3/ht+9AiBnkiefDarkSVPOi5p/lheh2lpfL1eZUV1Etq+X6+PLO9yF59xX+8j0IfRh6YRgghCkh/KmxhNb0PppI9JxASCCiEJLAnkFBO8ngahvQKPRhmhYhwk+VixIo/Ph5TuL7ECGKEAl8yLMK4KwkhzsUpZDFiCWEcRrFiDLR9s22KKs0LzDjhEeERQhzhBmiMfvwHfvwHVDGqiKrqqLphqWohqrpqqZrp7VWFDEatNpUFF1RhahTR9XNp2k6GAziOK5/Os1m0/d9WZbreXR1dcUYOz8/r03GWLfb/d0o7nSuXdet32g0GqqqSpJU661WS1VV8XM5Qdf1j0PyV48iCoVRXMA8AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bdc24d10e0c62bf3a5f9e8e0b9fea8be/e4706/Tasker_MD_Night_(2).avif 230w", "/en/static/bdc24d10e0c62bf3a5f9e8e0b9fea8be/01822/Tasker_MD_Night_(2).avif 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bdc24d10e0c62bf3a5f9e8e0b9fea8be/a0b58/Tasker_MD_Night_(2).webp 230w", "/en/static/bdc24d10e0c62bf3a5f9e8e0b9fea8be/0bafe/Tasker_MD_Night_(2).webp 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bdc24d10e0c62bf3a5f9e8e0b9fea8be/81c8e/Tasker_MD_Night_(2).png 230w", "/en/static/bdc24d10e0c62bf3a5f9e8e0b9fea8be/0e0c3/Tasker_MD_Night_(2).png 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bdc24d10e0c62bf3a5f9e8e0b9fea8be/0e0c3/Tasker_MD_Night_(2).png",
              "alt": "Tasker - Add a Task",
              "title": "Tasker - Add a Task",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 9
    }}>
      <li parentName="ol">{`Name the task and confirm the entry.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "432px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/965d488b254aba24eecf74025927ec4c/0e0c3/Tasker_MD_Night_(3).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "177.82608695652172%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAkCAIAAAAGkY33AAAACXBIWXMAAAsTAAALEwEAmpwYAAAB9ElEQVRIx+2WS6vaQBTHZyYxTzN5J2OiITNivLfRLDQqRYNCFoIbN3KX4a7Fb9GP0S9bzLS3ttxSKrSL4o8/w5nDOczhMC8wmUzyPA+CEAAAIQQtEEKEEAAgSZKyLKfTaV3XeZ6naZpl2W63S9O01+uBroHDXmRZJiE9y7Ljft91PZ4NIFRVTdN1WZbjODYwlhVFUVXX8zTt6gQ0GXz+9JqP2XKxmHx4Xi3KQRyB95Ak6Skb8do8x0EIAjvqrz4uQjoK6aifPcejJ6SbomF/F+ZyJMuzogGfqm4oYgdAtQtEBSr61WhHUcfCLwQVnRtIMwQdX2tA4G4gvF8PHty15d7nLy8ry3IURbZtW5YVhiEhxHEc3/eDIBAE4TfJCCGMsd5iWZZpmt1u1zAM0zT5xfQfNuzBPzsPf5yDEIIQyrLMX0bpBqHlJ89tMNhut3VdJ0my2WxWqxVjbNhCKZ3NZvP5nFL65lkul/v9nlJ6OByqqgJN05zP5yiKmqY5Ho/D4XDUQildr9dVVTHGshbGWF3XLy8vaZpeLpfT6QRc1zVNMwgCu8W/wf2G53lvHkIID3Zd92ufbNsuy1JVVQCAIAjj8dj3fd4UTdOKopAkiU+LojAM44cm67rOGOMREEJCCMaY251OhxAiiiKPj+P4+idoE78AFDI8RYyf514AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/965d488b254aba24eecf74025927ec4c/e4706/Tasker_MD_Night_(3).avif 230w", "/en/static/965d488b254aba24eecf74025927ec4c/01822/Tasker_MD_Night_(3).avif 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/965d488b254aba24eecf74025927ec4c/a0b58/Tasker_MD_Night_(3).webp 230w", "/en/static/965d488b254aba24eecf74025927ec4c/0bafe/Tasker_MD_Night_(3).webp 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/965d488b254aba24eecf74025927ec4c/81c8e/Tasker_MD_Night_(3).png 230w", "/en/static/965d488b254aba24eecf74025927ec4c/0e0c3/Tasker_MD_Night_(3).png 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/965d488b254aba24eecf74025927ec4c/0e0c3/Tasker_MD_Night_(3).png",
              "alt": "Tasker - Add a Task",
              "title": "Tasker - Add a Task",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 10
    }}>
      <li parentName="ol">{`Press the Plus button to open the Action Category dialogue.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "432px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7ff6d0bcff6033c718d6aa292b3c6d46/0e0c3/Tasker_MD_Night_(4).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "177.82608695652172%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAkCAIAAAAGkY33AAAACXBIWXMAAAsTAAALEwEAmpwYAAAElElEQVRIx41V21LiShSNXERFreIe9JQHTPpOLp0OJMAQEi4xmjjOFCWPTtW8z/+/noIeM8LxYdYD1bvJ2nv12jtpZbBHp9NRFKVQKJy8o1AoKIpyd3cnhGCMLZdLSmmv14MQhmHY6/W63a5SrVZrtXqpWFQ+Q6VSudhDVdXLy8vKHvV6/ezs7PT0VLmsVidcb7eajWar1WrX6w1VVa+urovFUvn0tFAonF9c3NzenpwUSqVyo9EoFou7zfOzXe7y+cV9/19GKYGAIoAhJAgiXQP9HrjvA12H932k3UOtD+77GGMdAISQ2r1RimVFOSk2Gk02jWj8jUaPZDwn8we6eKJhQr8sabCii5RGj3T+QGdrTAjGmDHWvblVCvuTNus16ozIlxWdLclwTIaTXYpJuF+PyTSii5QEq90mxhgAipHaaf92pXV7N/C/INuFtsBiRFyfiBERI8iHkA93a2f3i50REj4U/mA87/a13+Rmu4MN0/UnSfrsjDzEDGJYkA2CxTJYrHahuQuFP14nj7NoSQxLvf3nndxoEIxty8rSdMAYgpBgrGvaIori9VrXNIIxgtAYsG8vL77nEYxVVX0nN5uEUsZYmqYIIUwIoVTTdSFc4bqarhNKMSEIoTCKGBsQxlS1+4FMiOM40+mUc472sG07iqIgCGzb3mXEu5yTycQwDELIYWVChBDb7VaSZT/W63WSJJZlyXSMse1267ouxng3mx/JjLHNZpMXAQCYpsk513WdEAIhpJT++vVrNBpBCA/IlFLHcVzXlXUwxpRSsQdjDCEkz2UYxnA4NE1Tvkh/Kk+n081mMx6PAQBkb0+SJGma4j0IIbPZ7OvXr0mS2LZ9QKaUQghnsxmEUD7a6/WSJNlsNr1ej1KKMdY0zfd9qevAMEop59z3fcMw5N8IIcdxhBB5yDkfDoeWZbFdqw7dDsPw5eXF930pGwAQhuFisZAhhNDzvDRNZ7PZcaswxpzzHz9+5Pbouu55nu/70m2MMYQwiqJP+kwpNU3TdV3GGH6HaZqWZeUhY0wIYVkWpfRYdhRF379/n06nYP+6G4aR7GGapjx2HMfPz89ZljmO0263D8iGYfz8+VMqlLLDMFytVh9lx3E8HA4/cVuOAQBAipRu59MqM9q2LefngMwYi+M4SZIwDPPezOfz9Xqdl/U8L8sy+Q0+PrPjONvtVhomZQdBsFwuc9kIoSzLPM87li3/nkwmuq5LkRBC27Y553Lm5M5oNJKp/5Dr9boQIo7j+Xz++PgoW80YC/cwDANCyBhbLBbz+TyOY9d1D9xmjHme9/r6KoSQKgAAaZpmWZZbaNv229tbEATH44kxtizr6elJ07T8zFEUxXH8sVUPDw+O4yCEjt9nCGG/35fGSMMBANKCfEfTNPlVOKg8Ho+DIJCa6Qcwxugh5Dj9qVyr1WRiCKH+F4AQtlqtT+5UeTnni/+Hcn1Mk7d5nkL5G8jnarWa67rn5+eKohSLRUJI/pWqVquc891Vvgfn/Pr6+jdRkqvVar/fL5fL8olOp3N1dSXXpVKp0+mUSiUZ3tzcVCoVWfk/xSwlR5+hP9QAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7ff6d0bcff6033c718d6aa292b3c6d46/e4706/Tasker_MD_Night_(4).avif 230w", "/en/static/7ff6d0bcff6033c718d6aa292b3c6d46/01822/Tasker_MD_Night_(4).avif 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7ff6d0bcff6033c718d6aa292b3c6d46/a0b58/Tasker_MD_Night_(4).webp 230w", "/en/static/7ff6d0bcff6033c718d6aa292b3c6d46/0bafe/Tasker_MD_Night_(4).webp 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7ff6d0bcff6033c718d6aa292b3c6d46/81c8e/Tasker_MD_Night_(4).png 230w", "/en/static/7ff6d0bcff6033c718d6aa292b3c6d46/0e0c3/Tasker_MD_Night_(4).png 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7ff6d0bcff6033c718d6aa292b3c6d46/0e0c3/Tasker_MD_Night_(4).png",
              "alt": "Tasker - Add a Task",
              "title": "Tasker - Add a Task",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 11
    }}>
      <li parentName="ol">{`Select Net.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "432px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/31da51514d36cb8e3ee7ef21d5c184ca/0e0c3/Tasker_MD_Night_(5).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "177.82608695652172%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAkCAIAAAAGkY33AAAACXBIWXMAAAsTAAALEwEAmpwYAAAEi0lEQVRIx31Wa1PiSBSNAjpuwFEQBZaZSNLvTkJI52lIJGgYGUDGD7v//6dsJa08rJk9RXXd3OrDPfeR7ihmhdvbW0VRTk5OlA9Iezgcuq5rmmaapvf395qmAQCyLNM0rd/vK6qqXl23Ly4uGufnZ+dfzt7X0micnV1+/fr16qrZbGqadnV11Wq1ms1mv99XVfXs7ExpNVWLQQgAAjoCOoYQAQMZBgIAAwPqI6DrEEJd1wEAsNxYroNBv5TX+EvVvn/j6TNf/cuLXyxb8J//sOcNS59YseWLXzwtKOf0A4RSzvlgOFRqDUU5rXVubpjr0fiRRSl1BA2S8uf61E9YaQSEUvIBTAhjrNcfKKf1Mnj37+88nCLXRyJAfkxFQEWAvQiJEHkh9iL5d9iLkSidPMr6I/Be2E63a1l2hvAM4QRjyk1kmi5leeXxCEWMM9MUQeCHkR9G9mRSRpa4uekwRvPR/etwODUMjDHC2IVw8224+v5NQAgx5oz9WCyenubL5UsYBLK1knzDOOeMRUKwXW4Yu7btOw7BWHoMwwiCAGNMKe31ensyJQQYBmMMAFDyMDZ0nRBCKQVSC0IQwvF4jBDCGO/J7Xbbdd00TeM4TpKEMWaaZpZlDxWkRwgxnU6DIJDOvex2uy2EKIpiNpvleS7JRVHkeT6rwDkPgiDP8yzLZrNZlmV3d3d72YQQCGEYhqPRSGY4Go0mFfRKP0LIMIw4jquc8FHOpmlyzm3bZozRaiSqGmNpUEoxxgCA96IQcpSzEOL19XW73W42G8uyIIQPDw/z+fzx8XE6nUIIXdd9fn5O03Q+n6dpeiSbUpqm6Xa7TZJE1lMIEQRBWAEhZNt2FIaybMnDwxFZqp1Opwih9w4BICoYAFBKEcYjw4iTBFUjdNznKqtPqUoJpYcQE+MxQjalFsYOY3typ9OJoqgoivl8vlgsJpMJY2y1Wr28vDw9Pf1cLpllCQB+3t+/aNoPTXtE6PaQPB6PHccRQnieZ5ompdT3/SiKggqcMUiIg1AGYQwA4fxItswzSRIAgJSt6/pkMhFCyD5TQhDGXhjS8iVgR2TOOf6AHBJKKWNMjgc5BvuUs23bLxXW67XcsV6vl8vlukJZbYQsy9put1EUUUrLo/OwVbZt+75vWZYkm6Y5Ho9d17VtexfT8zxW4Ui27HMcx7s+l1MlhCuEnEepP47j35D5B2SeZWKEMIToQQksy5Ib+GG1O52O4zjr9booitVqVQ4MITNdTw0jH41yw0AI+b7/utlkWfb29pbn+fExVAX0PI8cHEOmadqWtQsuB14K/Jwzxtj3fQjhbqtt247joAOyLPVvCiaHzDRNfLB7Z+9qhjH+HJlSOp/P397e0jSVbz/5Az6Tu90ur26jMAzlyfo/kCOwH5J2uw0qjMdjXdcNw5DrnwAh7Ha7+3u8VqvV6/WTk5PT01P5eFrh0JDYOfdfANfX157nXVxcKIpSq9UIIbuDRlVVx3HKq7yC4zitVuvo80FVVV3XG42GdPZ6vcvLS2nX6/W7u7t6vS4fB4PB+fm5JP8HRYgrqa9UUMkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/31da51514d36cb8e3ee7ef21d5c184ca/e4706/Tasker_MD_Night_(5).avif 230w", "/en/static/31da51514d36cb8e3ee7ef21d5c184ca/01822/Tasker_MD_Night_(5).avif 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/31da51514d36cb8e3ee7ef21d5c184ca/a0b58/Tasker_MD_Night_(5).webp 230w", "/en/static/31da51514d36cb8e3ee7ef21d5c184ca/0bafe/Tasker_MD_Night_(5).webp 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/31da51514d36cb8e3ee7ef21d5c184ca/81c8e/Tasker_MD_Night_(5).png 230w", "/en/static/31da51514d36cb8e3ee7ef21d5c184ca/0e0c3/Tasker_MD_Night_(5).png 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/31da51514d36cb8e3ee7ef21d5c184ca/0e0c3/Tasker_MD_Night_(5).png",
              "alt": "Tasker - Add a Task",
              "title": "Tasker - Add a Task",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 12
    }}>
      <li parentName="ol">{`And then the HTTP GET action.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "432px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ac7ecc9cb0d7986650289f08fbff0a25/0e0c3/Tasker_MD_Night_(6).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "177.82608695652172%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAkCAIAAAAGkY33AAAACXBIWXMAAAsTAAALEwEAmpwYAAAD9UlEQVRIx42W23KjOBCGBUggEBJnhE/gQ3DAxmAcjxOvdz2bTV4hF0mqsjd5glxM1d7uY+zTbhllbGeS8eSrrrLKUuvwd7cEKIpiOp1yzgEAkiSBBkmSZFkGAHQ6naqqptPp7e3tZDLp9Xppmm63236/v3MxKeOttmUx3w88z3NctxfHlDExB8bYNKll2VHUsizbcVyMsef7hkE0TQODuPffv39fVJN5VV1+WU6ybJpnnusCAMTiQeD/9edXCCEAYFZMFUUBAPiuu+t12t2rzaUfD91u34+H0XAsEyYZDDIXUkcilt3qjWZz1fIgc4kfQeog5mIngMwB0LQkTBG1se0Joz43fU68UJhueypzdNsTDc1yoWkRL0TUBulodP31j9Xy4upy9ftv64t6blGT6Ng0dKJjomMD7wxrqoaghhCUJQ3Bf759G6dngDIWRRGPWmHIgyAMgtCklBDTIGRnhqEbBtZ1DWOtQVEUSuly+QWpKvgQIdWnGAwGdV1nWXZ1ddVE8iyKotMu8mtGQMTaMR+OvXgQjc7tTuJ2+7rPgUEBYT810wYqBgqEsixLsgwRkhUFIRUhVdU0jHWMm6NirGoa0lSk7rrEMA1jWVZAv9+/ublZLpeGYTDGKKWMMdM0SYPRoDcIwSCEqqq+vLykaQoopd1uNwxD8h2zQTSE/95ZVVVZliml6/Va/VDtfXl8KgpRFOV5Ph6Pu91ukiTj8ThJktFoNBwOsyzL8zzLsrIsLcvazy4yfMf5+fl2u63rOs/z7XZbluVms6nrerVardfrNE2vr6/X63Wv1yuKwrbtN7sLwzCO4yiKXNeN47jdbidJ0ul0OOdJklBKfd+P49jzvOFw6LquJEkIoQ+OIK4BqUF0K4oiGmK1/f87Wq3WarUqy3LWEIahaZq0YS+7CBhuUFUVQvj8/DwYDEAQBFVVFUVRVdVisQjD0DCM93HGR4VhWdbd3Z04/I/bVo6Q3yJ95+Bg2zbn3LZtz/MOMThdGM1EB2fHcTzPg02q/xIx7E0+SZKkHoHeAhv24r+qPZlMZrNZURRRFB2r9WFhIIQURXl8fEySBPi+P51Oq6qq67rT6ei6TnfXEDmt9v39veM4PyqhfMR7wQ8OjLEgCCzLsm37k7fXYZjjOJxz13WF2iIBT/NGtp8Jfqz5Xu3DtqMoms/nZ2dneZ6XZZllGWOMHPFebVmWHx4e+v3+rh4Xi0WapvP5fLPZjEajfUnsY/Ze7aenJ7d5DH8t+Cm199V7/LifVvt1gPhxHGe5XOq6LlbO81x8KwAACCF1Xe/vyrquWfP07xyFMyFkMBgghMQIzjmlVLQhhJxz8bgDANrt9u6boOF/uhRxuHvCiY8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ac7ecc9cb0d7986650289f08fbff0a25/e4706/Tasker_MD_Night_(6).avif 230w", "/en/static/ac7ecc9cb0d7986650289f08fbff0a25/01822/Tasker_MD_Night_(6).avif 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ac7ecc9cb0d7986650289f08fbff0a25/a0b58/Tasker_MD_Night_(6).webp 230w", "/en/static/ac7ecc9cb0d7986650289f08fbff0a25/0bafe/Tasker_MD_Night_(6).webp 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ac7ecc9cb0d7986650289f08fbff0a25/81c8e/Tasker_MD_Night_(6).png 230w", "/en/static/ac7ecc9cb0d7986650289f08fbff0a25/0e0c3/Tasker_MD_Night_(6).png 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ac7ecc9cb0d7986650289f08fbff0a25/0e0c3/Tasker_MD_Night_(6).png",
              "alt": "Tasker - Add a Task",
              "title": "Tasker - Add a Task",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 13
    }}>
      <li parentName="ol">{`Now configure the HTTP GET command to send the CGI command to the IP address of your camera (see below).`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "432px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/efde47c259b6e5a6c419ecf04923fd76/0e0c3/Tasker_MD_Night_(7).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "177.82608695652172%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAkCAIAAAAGkY33AAAACXBIWXMAAAsTAAALEwEAmpwYAAACGUlEQVRIx+2VTWvbQBCGZ7WyNvr+tLTWKpJ2pciuvzCOKxcSJwcdDL74Ynw0PZv8jP6M/tg2UmrSECi9+2FYZl5mGHgPszCdTieTSRhGAIAQghaEkCRJABDH8WKxmM1mTdOMx+Msy6qqenp6yvM8iiIwTCsaxI5jUzpwHJclie8H3TQgdKOqmqYphDDGbMfRdUMhJAgCTdMJIcDT258/vk9H5bquZ5Pxt/rrLYvf9revaRilEABwQ5RRdSdjDACB70kSAnfAVvXSS3IvySJehlmBdFs23S6w4dx4oUWTnuXJlhekQvUjbDqqH8mWB4rhWv2YlcN8NEmKKikq2bCRamDNlFQD65akmahNsG4B0STN7MTXMi9dRqM8TdOEiTybjr/IMu5hSUJIltDFws/Ji9uYsYhSOhg4rluWJed8Opu5npdmmUJI5/7n8QGE3rb9Y+eVK1euXLnczf+ekV7POyKEdD+j8g7c8kF53wzPz89N06Rp+vj4uF6vhRBFC+d8uVze399zzi9KXdfb7ZZzvtvtNpsNnE6n8/kcx/HpdNrv90VR3LVwzh8eHjabjRCiahFCNE1zPB7zPH95eTkcDuD7vm3bYRi6Lf13+H8IguCiUEq7Zt/333xyXXe1WqmqCgAY4+Fw2O/3O1M0TZvP54qidOV8PjdN8y+TdV0XQnQdCCFKqWVZXd7r9Silsix3/Ywx0v5+vwd/AS8DQFPEhxq/AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/efde47c259b6e5a6c419ecf04923fd76/e4706/Tasker_MD_Night_(7).avif 230w", "/en/static/efde47c259b6e5a6c419ecf04923fd76/01822/Tasker_MD_Night_(7).avif 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/efde47c259b6e5a6c419ecf04923fd76/a0b58/Tasker_MD_Night_(7).webp 230w", "/en/static/efde47c259b6e5a6c419ecf04923fd76/0bafe/Tasker_MD_Night_(7).webp 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/efde47c259b6e5a6c419ecf04923fd76/81c8e/Tasker_MD_Night_(7).png 230w", "/en/static/efde47c259b6e5a6c419ecf04923fd76/0e0c3/Tasker_MD_Night_(7).png 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/efde47c259b6e5a6c419ecf04923fd76/0e0c3/Tasker_MD_Night_(7).png",
              "alt": "Tasker - Add a Task",
              "title": "Tasker - Add a Task",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 14
    }}>
      <li parentName="ol">{`Then press the play button to test the action.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "432px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/47caca4726fbd2df370be6d99ce5585a/0e0c3/Tasker_MD_Night_(8).png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "177.82608695652172%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAkCAIAAAAGkY33AAAACXBIWXMAAAsTAAALEwEAmpwYAAADLUlEQVRIx6VVW3PiNhQWvuD4JttYxrIkQLYwhjVokkwKbYcEaLrTlrTblzyl05c89n/0tdOnPva3tl6RbHaXYb3tNxrmSEff+Og7F8B8Pq/rOk1TAICmaZ0naJoGACCEnJ+fSyk3m40QgnOe5/nNzQ3nHGMMPB9iyiyrC94yXi7Q6di27TiOdXZGCHU978y2LctCCDmua1kWQL3e378/jhgGoKGDdoh7kaZ1QJjRr6+/ginT/cgKkRWiboDMoNcN48M2RGYQd0NkRejpJHYTfBYlIMvHaV7yac2nNS1Kko+ZmBSz+WBckXxMinJQTkUtefWKFCV9uxpvOc3yMWAk21yvv3v9erX8gpGMEjIpy/3+B7lYkCzLcDp/Nfvxbv/laklwykg2ZPSb7fZcStxPQNTr+T70IYyiCPXT7HqPdz+j9T7dvMk2PyV00IsiGIaN9wk+hJ7vI4SA67pBENi2fZBCN4BudswuMEygmwB0PhbS8zzGWHM4Go0WiwWltI3a6sJwOLy9vTVN87BXVdFA199bx6AoTRVBCOM4bh7QAooWx7GUsiEPBoOqqoqiaE+mlN7d3TUV9rH7k3juAhAEQb/fD8MwCAIIoa7rJ2iqW7IsWy6Xjc0Y2263s9msrmspZbfbPRG2Is9ms4eHB2X/l7AP73ddFyEUBIFhGC3znCRJVVXNvizL6XRKCGmS3k7tPM/X6/VnB/xh2BBCjDFCyDCMll/2PO/i4qLJsxBiMpmMRqPTOr8kQwh3u53jOO85tHZ4bgeQpqkQAmPs+/4nX65ySym9v79v7OFwqGpblffpbCkv5/zx8fHIzc9Tu9/vc86zLGOMvZsnJ2HbNue8sRhjKmwpped5bdQOgqCu6+PulpE3v0ff0zLh/w8WyTVEuyQ3Xd/QNaMdVLWA6vv79a+/1W9+EYuLgvOiEMVJCCHyPL+6upJSgsV08teff3x7sx4wOqmqsgWEELvdbrVagTTLxLikg0HS7yfHoP5iPjg8TGulWxRFl5eXqkh0Xa+qKkkSJYrjOC9nm5RSdcG7meZ5XlEU6kan08EYQwiVbZomxvi52wkhamL/+9V/AApYaB34ruvFAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/47caca4726fbd2df370be6d99ce5585a/e4706/Tasker_MD_Night_(8).avif 230w", "/en/static/47caca4726fbd2df370be6d99ce5585a/01822/Tasker_MD_Night_(8).avif 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/47caca4726fbd2df370be6d99ce5585a/a0b58/Tasker_MD_Night_(8).webp 230w", "/en/static/47caca4726fbd2df370be6d99ce5585a/0bafe/Tasker_MD_Night_(8).webp 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/47caca4726fbd2df370be6d99ce5585a/81c8e/Tasker_MD_Night_(8).png 230w", "/en/static/47caca4726fbd2df370be6d99ce5585a/0e0c3/Tasker_MD_Night_(8).png 432w"],
              "sizes": "(max-width: 432px) 100vw, 432px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/47caca4726fbd2df370be6d99ce5585a/0e0c3/Tasker_MD_Night_(8).png",
              "alt": "Tasker - Add a Task",
              "title": "Tasker - Add a Task",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 15
    }}>
      <li parentName="ol">{`Add an icon for the task and go back to the overview.`}</li>
    </ol>
    <p>{`Now (see 13 above) we need to create the HTTP command, which will be sent to the camera when the task is activated. Enter the IP address of your camera as Server Address - do not forget to attach the camera to Webport if it is not 80. If your camera has e.g. 192.168.178.200 and the camera is locally accessible via the port 8081 (the HTTP port can be viewed in the web interface under Network / IP Configuration), you must enter 192.168.178.200:8081 as the server address. This is followed by the corresponding CGI command in the Path input field.`}</p>
    <p>{`The CGI command that activates Area 2 of your camera with 30% sensitivity is:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`/param.cgi?cmd=setmdattr&-name=1&-enable=0&-s=75&-x=0&-y=0&-w=1280&-h=720&cmd=setmdattr&-name=2&-enable=1&-s=30&-x=0&-y=0&-w=1280&-h=720&cmd=setmdattr&-name=3&-enable=0&-s=0&-x=0&-y=0&-w=1280&-h=720&cmd=setmdattr&-name=4&-enable=0&-s=0&-x=0&-y=0&-w=1280&-h=720`}</code></pre></div>
    <p>{`Please change in the command the username (usr) admin and password (pwd) instar according to your camera login. Please note that the command above will span area 2 over the entire image. But you can simply edit the size of the area directly in the command. The variables x and y correspond to the start point of the range (in the example above, both variables are equal to 0 - that means that the range starts in the lower right corner). The size of the area is determined by its width and height. In the example above, the width w = 1280 pixels and the height h 0 is 720 pixels. This means that the area is covered over the entire image.`}</p>
    <p>{`In the following we will bring together the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Tasker/Schedule_PTZ_Task/"
      }}>{`Day & Night Position`}</a>{` tasks and the corresponding motion detection defined above via a `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Tasker/Schedule_Profile/"
      }}>{`Time Controlled Profile`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      